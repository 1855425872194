exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-artwork-list-template-js": () => import("./../../../src/templates/artwork-list-template.js" /* webpackChunkName: "component---src-templates-artwork-list-template-js" */),
  "component---src-templates-artwork-template-js": () => import("./../../../src/templates/artwork-template.js" /* webpackChunkName: "component---src-templates-artwork-template-js" */),
  "component---src-templates-exhibition-list-template-js": () => import("./../../../src/templates/exhibition-list-template.js" /* webpackChunkName: "component---src-templates-exhibition-list-template-js" */),
  "component---src-templates-exhibition-template-js": () => import("./../../../src/templates/exhibition-template.js" /* webpackChunkName: "component---src-templates-exhibition-template-js" */)
}

